


import { Vue, Component } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, ENV } from "@/config";

import CommonMixin from "@/mixins/common.mixin";

@Component
export default class ManageTwilio extends CommonMixin {
  public purchasedNumbers = [];
  public environment = ENV;
  public async getAllPurchasedNumbers() {
    // let loader = this.$loading.show();
     this.$store.state.wemloLoader=true;
    try {
      let response = await Axios.get(
        BASE_API_URL + "communication/getAllPurchasedNumbers");
      if(this.environment == 'stage' || this.environment == 'production')
      this.purchasedNumbers = response.data.purchasedNumbers;
      // loader.hide();
       this.$store.state.wemloLoader=false;
    } catch (error) {
      // loader.hide();
       this.$store.state.wemloLoader=false;
      console.log(error, "error");
    }
  }

  public async releaseTwilioNumber(numberSid, staffUserId) {
    try {
      let confirmReleaseNumber = true;
      //if (staffUserId) {
        await this.$dialog
          .confirm("Are you sure,you want to release this number")
          .then(function(dialog) {
            confirmReleaseNumber = true;
          })
          .catch(function() {
            confirmReleaseNumber = false;
          });
      //}
      if (confirmReleaseNumber) {
        // let loader = this.$loading.show();
         this.$store.state.wemloLoader=true;
        let response = await Axios.post(
          BASE_API_URL + "communication/releaseTwilioNumber",
          {
            numberSid: numberSid,
            staffUserId: staffUserId
          });
        if (response.data.status == "success") {
          this.purchasedNumbers = this.purchasedNumbers.filter(
            e => e.sid != numberSid
          );
          this.$snotify.success("Number released successfully.");
        } else if (response.data.status == "error") {
          this.$snotify.error("Some error occured.Please try again.");
        }
        // loader.hide();
         this.$store.state.wemloLoader=false;
      }
    } catch (error) {
      this.$loading.stop();
      console.log(error, "error");
    }
  }

  mounted() {
    this.getAllPurchasedNumbers();
  }
}
