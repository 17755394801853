



import { Component } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, ENV } from "@/config";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import { wemlouserdetail } from "@/models/superadmin/superadmin.model";
import Autocomplete from "vuejs-auto-complete";
import UpdateUserInfo from "../../../views/UpdateUserInfo.vue";
import { HollowDotsSpinner } from "epic-spinners";
import Multiselect from "vue-multiselect";
import StarRating from "vue-star-rating";
import UpdateUserStatus from "@/views/UpdateUserStatus.vue";
import ProfilePicture from "@/views/ProfilePicture.vue";
import Debounce from '@/decorators/Debounce';
import _ from 'lodash';

@Component({
  components: {
    Autocomplete,
    UpdateUserInfo,
    HollowDotsSpinner,
    Multiselect,
    UpdateUserStatus,
    StarRating,
    ProfilePicture,
  }
})
export default class getListBrokerComponent extends mixins(CommonMixin) {
  public wemloInfo = new wemlouserdetail();
  public width = 0.7;
  public defaultButtonName = null;
  public defaultWemloName = "Add Processor";
  public defaultSubmitName = "Add";
  public updateStaffUserId = "";
  public updateStaffUserType = "";
  public searchStaff = null;
  public ifEdit = false;
  public wemloDetail = [];
  public duplicateEmail = false;
  public modalUser: any = {};
  public wemloStaffList = [];
  public wemloStaffNames = [];
  public wemloStaffId = [];
  public updateStaffEmail: string = "";
  public updateStaffPhone: string = "";
  public updateStaffloanTxnId: string = "";
  public initialEmail: string = "";
  // public seniorUsers: any = [];
  // public seniorUsersForModal: any = [];
  public availablePhoneNumbers: any = [];
  public unusedPhoneNumbers: any = [];
  public assigningStaffUserId = null;
  public assigningStaffTwilioClientName = null;
  public environment = ENV;
  public dotLoader = false;
  public isEmailVerified = false;
  public index = 0;
  public inProcess = false;
  public errorMessage2 = false;
  public clearButton = false;
  public reportTo = [];
  public reportToName = [];
  public roleName = [];
  public wemloDetailWithRole = [];
  // public addManageRole = "Update";
  public personalInfo = [];
  public statusChangingIndex: any;
  public userInfo: any = {};
  public open = false;
  public searchList = [];
  public searchName = "";
  public allWemloStaff = [];
  public userLoader = false;
  public loadMoreScroll = false;
  public initialPagination = { start: 0, end: 15 };
  public pagination: any = JSON.parse(JSON.stringify(this.initialPagination));
  public currentLengthOnScroll = 0;
  public wemloStaffLen = 0;
  public userAccess: any = {
    create: false,
    allAccess: false,
    edit: false,
    view: false
  };
  public countViewedUsers = 0;
  public roles: any = [];
  public wemloStaffData: any = null;
  public accessCreate = false;
  public accessEdit = false;
  public accessView = false;
  public accessAllAccess = false;
  public reportees = [];
  public taskAccess: any = {};
  public staff = [];
  public wemloInfoReporties = [];
  public skip = 0;
  public list: any = null;
  public borrowersLoanTxnId = null;
  public status = "All";

  public get userTypes() {
    return this.$store.state.sessionObject.type;
  }
  public searchByType = null;
  public searchString = null;
  public showPopUpForInfo = false;
  public userStatus = null;
  public userIndex = null;
  public processorInfo = [];
  public accessLoader = false;
  public taskAccessLoader = false;
  public brokerName = null;
  public brokers = [];
  public userActiveStatus = false;
  public staffLen = 0;
  public processorRatingData = [];
  public taskRole = [];
  public allAccessRight = false;
  public profilepicture = null;
  public processorHaveLoans = [];
  public countLoader= false
  public processorManagerList = [];
  public processorAssistantList = [];

  public get computedWemloDetail() {
    const filtered = this.wemloDetail.filter(d => d.userId !== this.$userId);
    return filtered.map(i => {
      let tailReportToName = [];
      let reportToName = i?.reportToName || [];
      if (reportToName.length) {
        reportToName = reportToName.map((item, index, collection) => {
          const append = index + 1 !== collection.length ? ', ' : ''
          return {
            ...item,
            name: item.name + append,
          };
        });
      }
      const limit = 6;
      if (reportToName.length > limit) {
        tailReportToName = _.clone(reportToName);
        reportToName.splice(limit);
        reportToName.push({ name: `...and ${tailReportToName.length - limit} more` });
      }
      return {
        ...i,
        reportToName,
        tailReportToName,
      }
    })
  }

  public async addWemloUser(userType: any) {
    try {
      this.inProcess = true;
      let formValid = await this.$validator.validateAll();
      if (!formValid || this.duplicateEmail) {
        this.inProcess = false;
        return;
      }
      this.duplicateEmail = false;
        this.wemloInfo.firstName =
          this.wemloInfo.firstName.charAt(0).toUpperCase() +
          this.wemloInfo.firstName.substring(1).toLowerCase();
        this.wemloInfo.lastName =
          this.wemloInfo.lastName.charAt(0).toUpperCase() +
          this.wemloInfo.lastName.substring(1).toLowerCase();
        this.wemloInfo.email = this.wemloInfo.email.toLowerCase();
       
        if (this.defaultSubmitName == "Add") {
          this.wemloInfo.addedBy = this.$userId;
          this.wemloInfo.addedByUserType = this.$userType;
          let response = await Axios.post(
            BASE_API_URL + "wemloStaff/addWemloUser",
            this.wemloInfo);
          if (response.data.status == "duplicateEmail") {
            this.duplicateEmail = true;
            this.inProcess = false;
            return;
          } else {
            this.inProcess = false;
            this.wemloInfo["_id"] = response.data.userId;
            this.$snotify.success("Saved Successfully.");
            this.wemloInfo["userEmailVerified"] = false;
            this.wemloInfo = new wemlouserdetail();
            this.reportTo = [];
            this.clearButton = false;
            await this.getWemloUserList(true);
            this.$modal.hide("addUser");
          }
        } else {
          if (this.errorMessage == true || this.errorMessage2 == true) {
            this.inProcess = false;
            return;
          }
          let response = await Axios.post(
            BASE_API_URL + "wemloStaff/updateUsers",
            { wemloDetail: this.wemloInfo});
          this.$snotify.success("Update successfully.");
          this.inProcess = false;
          let emailChanged = false;
          let phoneChanged = false;
          this.wemloDetail.forEach(w => {
            if (w._id == this.wemloInfo["_id"]) {
              Object.keys(w).forEach(d => {
                if (d == "email" && w[d] != this.wemloInfo[d]) {
                  emailChanged = true;
                }
                if (d == "phone" && w[d] != this.wemloInfo[d]) {
                  phoneChanged = true;
                }
                w[d] = this.wemloInfo[d];
              });
              if (emailChanged) {
                w["userEmailVerified"] = false;
              }
            }
          });
          this.getWemloUserList(true);
          this.getWemloProcessorManagerList();
          this.defaultWemloName = "Add User";
          this.defaultSubmitName = "Add";
          this.$modal.hide("addUser");
          this.reportTo = [];
          this.ifEdit = false;
          this.errorMessage = false;
        }
    } catch (error) {
      this.inProcess = false;

      console.log(error);
    }
  }

  public get accessRight() {
    const { type, access } = this.$store.state.sessionObject;

    if (type == "Wemlo Processor") {
      if (access && access.length > 0) {
        return access[0].subSections;
      }
    }
    
    return [];
  }

  @Debounce
  public async getWemloUserList(
    mountCall: boolean = false,
    statusCall: boolean = false
  ) {
    this.skip = mountCall ? 0 : this.skip + 20;
    if (mountCall) this.userLoader = true;
    try {
      if (this.searchString == "") {
        this.searchString = null;
      }
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/getListWemloUser/",
        {
          status: this.status,
          userId: this.$store.state.sessionObject.userId,
          options: {
            skip: this.skip,
            limit: 20
          },
          userType: this.$store.state.sessionObject.type,
          searchByType: this.searchByType,
          searchString: this.searchString,
          searchStaff: this.searchStaff ? this.searchStaff : null,
          brokerId: this.brokerName ? this.brokerName.userId : null,
          statusCall: statusCall,
          accessRight: this.accessRight
        });
      this.pagination = response.data.pagination;
      this.brokers = response.data.brokers;
      if (mountCall) {
        this.wemloDetail = response.data.wemloDetail;
        this.allWemloStaff = response.data.wemloDetail;
      } else {
        this.wemloDetail = this.wemloDetail.concat(response.data.wemloDetail);
        this.allWemloStaff = this.allWemloStaff.concat(
          response.data.wemloDetail
        );
      }
      this.staffLen = response.data.wemloStaffLen;
      this.searchList = response.data.wemloDetail;
      this.currentLengthOnScroll = response.data.wemloDetail.length;
      this.userLoader = false;
      this.getTotalWemloStaffCount({
        searchStaff: this.searchStaff ? this.searchStaff : null,
        addedBy: this.brokerName ? this.brokerName.userId : null
      });
      if(mountCall) this.countViewedUsers = 0;
    } catch (error) {
      this.userLoader = false;
      console.log(error);
    }
  }

  public removeValue() {
    this.wemloInfo.reportTo = "";
    this.clearButton = false;
  }

  public getValueOnChange(type) {
    this.status = "All";
    this.searchStaff = null;
    if (this.searchByType == null) {
      this.wemloDetail = [];
      this.searchByType = null;
      this.searchString = null;
      this.getWemloUserList(true);
    }
    this.countViewedUsers = 0;
  }
  public updateStaffList() {
    this.getWemloUserList(true);
  }
  public removeClearButton() {
    this.clearButton = true;
    if (this.wemloInfo.reportTo.length > 0) {
      this.clearButton = true;
    } else {
      this.clearButton = false;
    }
  }
  public capitalizeFirstCharacter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public getFullName(wemloStaffList) {
    let firstName = wemloStaffList.firstName;
    let lastName = wemloStaffList.lastName ? wemloStaffList.lastName : "";
    return (
      this.capitalizeFirstCharacter(firstName) +
      " " +
      this.capitalizeFirstCharacter(lastName)
    );
  }

  public async cancel() {
    this.wemloInfo = new wemlouserdetail();
    this.defaultWemloName = "Add User";
    this.defaultSubmitName = "Add";
    this.errorMessage = false;
    this.clearButton = false;
    this.$modal.hide("addUser");
    this.ifEdit = false;
    this.duplicateEmail = false;
  }

  public closeModal() {
    this.wemloInfo = new wemlouserdetail();
    // this.seniorUsersForModal = [];
    this.defaultWemloName = "Add User";
    this.defaultSubmitName = "Add";
    this.errorMessage = false;
    this.clearButton = false;
    this.$modal.hide("addUser");
    this.ifEdit = false;
    this.duplicateEmail = false;
  }
  public async closeUserModal() {
    this.wemloDetail[this.userIndex].userActive = !this.wemloDetail[
      this.userIndex
    ].userActive;
    this.userStatus = null;
    this.$modal.hide("accountActAndDeact");
    this.getWemloUserList(true);
  }
  public async showEditUser(userData, i) {
    this.index = i;
    this.ifEdit = true;
    if (userData.reportTo) {
      this.clearButton = false;
    }
    this.wemloInfo = Object.assign({}, userData);
    let data = await this.getSupervisorData(this.wemloInfo.userId);
    this.reportTo = data.map(e => {
      return {
        id: e.userId,
        name: e.firstName + " " + e.lastName
      };
    });

    /**
     * Load the assistant users from the selected processor
     */
    this.processorAssistantList = this.wemloInfo.reportees.map(reporteeId => {
      return data.find(staff => staff.userId === reporteeId);
    });

    // if (this.wemloDetail[i].reportToName)
    //   this.wemloDetail[i].reportToName.forEach((element, i) => {
    //     this.wemloInfo.reportTo[i] = userData.find(
    //       reportTo => reportTo.id == element.id
    //     );
    //   });

    // this.updateStaffUserId = this.wemloInfo.userId;
    this.defaultWemloName = "Edit";
    this.defaultSubmitName = "Save";
    this.$modal.show("addUser");
  }
  public async getSupervisorData(userId = null, wemloMember = null) {
    try {
      let resposne = await Axios.get(
        BASE_API_URL + "wemloStaff/getSupervisorData",
        {
          params: {
            userId: userId,
            wemloMember: wemloMember
          }
        }
      );
      let supervisorData = resposne.data.wemloStaff;
      if (wemloMember != null)
        supervisorData = supervisorData.filter(
          p => p.wemloMember == wemloMember
        );
      return supervisorData;
    } catch (error) {
      console.log(error);
    }
  }
  public editEmail(modalName, body) {
    let data = {
      userType: body.userType,
      modalName: modalName,
      firstName: body.firstName,
      lastName: body.lastName,
      email: body.email,
      userId: body.userId
    };

    this.personalInfo = [{ ...body, ...data }];
    this.$refs.updateUserStatus["openModalOrClose"](this.personalInfo);
  }
  public async updateBorrowerInfo(updatedData) {
    // this.pagination = this.initialPagination;
    await this.getWemloUserList(true);
  }

  public async openAddUserModel() {
    this.wemloInfo = new wemlouserdetail();
    this.wemloInfo["processorRelationship"] = null;
    // this.seniorUsersForModal = this.seniorUsers;
    this.wemloInfo.userType = "Wemlo Processor";
    // this.wemloInfo.position = "Junior";
    let supervisorList = await this.getSupervisorData();
    this.reportTo = supervisorList.map(e => {
      return {
        id: e.userId,
        name: e.firstName + " " + e.lastName
      };
    });
    this.$modal.show("addUser");
  }

  public async updateEmail() {
    this.updateStaffEmail = "";
    await this.getWemloUserList(true);
    this.getTotalWemloStaffCount(null);
  }
  public errorMessage = false;

  public async positionChangeValidation() {
    try {
      if (this.defaultWemloName == "Edit") {
        let response = await Axios.post(
          BASE_API_URL + "wemloStaff/updateUsers",
          { userId: this.updateStaffUserId, identifier: "Position Change" });
        if (response.data.status == "Position Change") {
          this.errorMessage = true;
          // this.wemloInfo.position = this.wemloDetail[this.index].position;
          setTimeout(() => {
            this.errorMessage = false;
          }, 1500);
        }
        if (response.data.status == "Report To") {
          this.errorMessage2 = true;
          //this.wemloInfo.position = this.wemloDetail[this.index].position;
          setTimeout(() => {
            this.errorMessage2 = false;
          }, 1500);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  get userType() {
    return this.$store.state.sessionObject.type;
  }

  public async openAssignWemloNumberModal(staffUserId, staffName) {
    // let loader = this.$loading.show();
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "communication/getAvailableNumbersList",
        {
          userType: this.userType, //currently No Use.
          staffUserId: staffUserId
        });

      this.assigningStaffUserId = staffUserId;
      this.assigningStaffTwilioClientName =
        staffName.split(" ")[0] +
        Math.round(Math.random() * 1000000).toString();
      if (this.environment == "production" || this.environment == "stage") {
        this.availablePhoneNumbers = response.data.availableToBuy;
      }
      this.unusedPhoneNumbers = response.data.purchasedAndUnused;
      this.$modal.show("assignWemloNumberModal");
      // loader.hide();
      this.$store.state.wemloLoader = false;
    } catch (error) {
      // loader.hide();
      this.$store.state.wemloLoader = false;
      console.log(error.response);
    }
  }

  public async assignStaffWemloNumber(number, numberType, numberSid = null) {
    try {
      let releasePreviousNumber = false;
      let assingingStaff = this.wemloDetail.filter(
        e => e._id == this.assigningStaffUserId
      );
      let previousNumber =
        assingingStaff[0].wemloNumber != ""
          ? assingingStaff[0].wemloNumber
          : null;
      let previousNumberSid =
        assingingStaff[0].wemloNumberSid != ""
          ? assingingStaff[0].wemloNumberSid
          : null;
      if (
        (previousNumber && this.environment == "stage") ||
        (previousNumber && this.environment == "production")
      ) {
        await this.$dialog
          .confirm("Do you want to release previous number")
          .then(function(dialog) {
            releasePreviousNumber = true;
          })
          .catch(function() {
            releasePreviousNumber = false;
          });
      }

      let loader = this.$loading.show();
      let response = await Axios.post(
        BASE_API_URL + "communication/assignStaffWemloNumber",
        {
          number: number,
          numberType: numberType,
          staffUserId: this.assigningStaffUserId,
          twilioClientName: this.assigningStaffTwilioClientName,
          releasePreviousNumber: releasePreviousNumber,
          previousNumber: previousNumber,
          numberSid: numberSid,
          previousNumberSid: previousNumberSid
        });
      if (response.data.status == "success") {
        this.wemloDetail.forEach(e => {
          if (e._id == this.assigningStaffUserId) {
            e["wemloNumber"] = number;
            e["twilioClientName"] = this.assigningStaffTwilioClientName;
          }
        });
        this.$modal.hide("assignWemloNumberModal");
        this.assigningStaffUserId = null;
        this.unusedPhoneNumbers = [];
        this.availablePhoneNumbers = [];
        this.$snotify.success(response.data.message);
      }
      if (response.data.status == "error") {
        this.$snotify.error(response.data.message);
      }
      loader.hide();
    } catch (error) {
      this.$loader.hide();
      console.log(error, "error");
    }
  }

  /**************************************************************************************************
   *                      ON CLICKING THE REPORT TOKEN SECTION CHECKBOX                              *
   ***************************************************************************************************
   * FUNCTION WORKING PROCESS -----------------------------------------------------------------------
   * Called from HTML send Wemlo Staff UserId in @param { userId },
   * Sends the post request to server with the modify variable,
   * if have to generate the token value will be true, otherwise false
   * and in response it will get the message and status code according the condition.
   */
  public async modifyReportClearToken(check, userId) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/modifyReportClearToken",
        { modify: check, userId: userId });
      this.$snotify[
        response.data.status != 200
          ? response.data.status == 204
            ? "info"
            : "error"
          : "success"
      ](response.data.message);
      this.pagination = JSON.parse(JSON.stringify(this.initialPagination));
      this.getTotalWemloStaffCount(null);
      this.getWemloUserList(true);
    } catch (error) {
      console.log(error);
    }
  }

  /**************************************************************************************************
   *                      ON CLICKING THE REPORT TOKEN SECTION EMAIL ICON                            *
   ***************************************************************************************************
   * FUNCTION WORKING PROCESS -----------------------------------------------------------------------
   * Called from HTML email icon in reports section,
   * send Wemlo Staff userId in @param { userId },
   * and send the mail to that processor containing token.
   */
  public async resendMailOfReportToken(staffId) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/resendMailOfReportToken",
        { staffId: staffId });
      this.$snotify[response.data.status == 200 ? "success" : "error"](
        response.data.message
      );
    } catch (error) {
      console.log(error);
    }
  }

  public closeAssignWemloNumberModal() {
    this.$modal.hide("assignWemloNumberModal");
    this.assigningStaffUserId = null;
    this.unusedPhoneNumbers = [];
    this.availablePhoneNumbers = [];
  }
  // public getWemloSeniorUsers() {
  // return  this.seniorUsers = this.wemloDetail.filter(e => e.position == "Senior");
  // }
  // public async cancelDeactivating() {
  //   this.$snotify.info("No changes");
  // }

  public async changeUserActive(processor, i) {
    try {
      let data = [];
      let sure = false;
      let msg = !processor.userActive
        ? "Are you sure you want to deactivate this processor ?"
        : "Are you sure you want to activate this processor?";
      await this.$dialog
        .confirm(msg)
        .then(e => {
          sure = true;
        })
        .catch(f => {
          sure = false;
          processor.userActive = !processor.userActive;
          this.$snotify.error("No changes");
        });
      if (sure) {
        this.$store.state.wemloLoader = true;
        if (!processor.userActive) {
          let checkProcessorHaveLoans = await Axios.post(
            BASE_API_URL + "broker/checkProcessorHaveLoans",
            { userId: processor.userId });
        this.$store.state.wemloLoader = false;
          if (checkProcessorHaveLoans.data.borrowersDetail.length > 0) {
            this.processorHaveLoans =
              checkProcessorHaveLoans.data.borrowersDetail;
              let text = `This processor has ${checkProcessorHaveLoans.data.borrowersDetail.length}  loans assigned. <br>
                          Deactiving will remove this process from the loans and you need to reassign new processor for the loans`
            await this.$dialog
              .confirm(text)
              .then(e => {
                sure = true;
              })
              .catch(f => {
                sure = false;
                processor.userActive = !processor.userActive;
                this.$snotify.error("No changes");
              });
            if (sure) {
              data.push({
                userType: processor.userType,
                userId: processor.userId,
                update: processor.userActive
              });
              await this.changeActivity(data, processor, i);
            }
          } else {
            data.push({
              userType: processor.userType,
              userId: processor.userId,
              update: processor.userActive
            });
            await this.changeActivity(data, processor, i);
          }
        } else {
          data.push({
            userId: processor.userId,
            userType: processor.userType,
            update: processor.userActive
          });
          await this.changeActivity(data, processor, i);
        }
      }
      this.getWemloUserList(true);
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  public async changeActivity(data, processor, i) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/changeUserActive",
        data);
      this.$snotify.clear();
      if (response.status == 200) {
        this.$snotify.success(data[0].update ? "User Activated" : "User Deactivated")
      }
      this.wemloDetail[i].userActive = processor.userActive;
      if (this.wemloDetail[i].userActive == false) {
        if (this.wemloDetail[i].activity) {
          let res = await Axios.post(
            BASE_API_URL + "wemloStaff/updateProcessorAudiTrail",
            {
              processorId: processor.userId,
              activity: false,
              performedBy: this.$store.state.sessionObject.userId
            });
          this.wemloDetail[i].activity = false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * To update the processor activity of load balancing is true or false
   */
  public async switchBalancing(processorId, i) {
    try {
      let sure = false;
      let msg = !this.wemloDetail[i].activity
        ? "Are you sure you want to stop sending new loan?"
        : "Are you sure you want to  send new loan?";
      await this.$dialog
        .confirm(msg)
        .then(e => {
          sure = true;
        })
        .catch(f => {
          sure = false;
          this.wemloDetail[i].activity = !this.wemloDetail[i].activity;
          this.$snotify.info("No changes");
        });
      if (sure) {
        await this.updateProcessorAudiTrail({
          processorId: processorId,
          activity: this.wemloDetail[i].activity,
          performedBy: this.$store.state.sessionObject.userId
        });
      }
    } catch (error) {
      console.log(error, "switchBalancing");
    }
  }

  public async showMoreDataOnScroll() {
    if (!this.loadMoreScroll && !!this.currentLengthOnScroll && (this.currentLengthOnScroll < this.wemloStaffLen)) {
      this.loadMoreScroll = true;
      await this.getWemloUserList(false);
      this.loadMoreScroll = false;
    }
  }
  public async getTotalWemloStaffCount(data) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/getTotalWemloStaffCount/",
        {
          userId: this.$userId,
          searchStaff: data.searchStaff ? data.searchStaff : null,
          brokerId: data.addedBy ? data.addedBy : null
        });
      this.wemloStaffLen = response.data.wemloStaffLen;
    } catch (error) {
      console.log(error);
    }
  }
  public hideAccessModal() {
    this.wemloInfoReporties = [];
    this.reportees = [];
    this.userAccess = {
      create: false,
      allAccess: false,
      edit: false,
      view: false
    };
    this.$modal.hide("accessRight");
  }
  public async openModelForAccessRight(data, i) {
    this.wemloStaffData = data;
    if (
      this.wemloStaffData &&
      this.wemloStaffData.userAccess[0] &&
      this.wemloStaffData.userAccess[0].canAccess
    ) {
      this.wemloStaffData.userAccess[0].subSections.forEach(element => {
        if (element.accessType == "create" && element.canAccess == true) {
          this.userAccess.create = element.canAccess;
        }
        if (element.accessType == "edit" && element.canAccess == true) {
          this.userAccess.edit = element.canAccess;
        }
        if (element.accessType == "allAccess" && element.canAccess == true) {
          this.userAccess.allAccess = element.canAccess;
        }

        if (element.accessType == "view" && element.canAccess == true) {
          this.userAccess.view = element.canAccess;
        }
      });
    }
    let staffsData = null;
    staffsData = await this.getSupervisorData(
      this.wemloStaffData.userId,
      this.wemloStaffData.wemloMember
    );
    if (staffsData.length > 0)
      staffsData.forEach(staff => {
        this.reportees.push({
          id: staff.userId,
          name: staff.firstName + " " + (staff.lastName ? staff.lastName : " ")
        });
      });
    this.wemloDetail[i].reportees.forEach((element, index) => {
      this.wemloInfoReporties[index] = this.reportees.find(
        reportTo => reportTo.id == element
      );
    });
    this.$modal.show("accessRight");
  }

  public async openModelForTaskAccess(data, i) {
    this.wemloStaffData = data;

    await this.getTaskAccessRight();
    await this.checkTaskAccess();
    this.defaultButtonName = "Update";
    this.$modal.show("taskAccess");
  }

  public async allTaskAccess() {
    if (this.wemloStaffData) {
      let processorId = [];
      processorId = this.roles.filter(
        p => p.checked && p.processorId.includes(this.wemloStaffData.userId)
      );
      this.allAccessRight = processorId.length === this.roles.length;
      this.taskAccess = JSON.parse(JSON.stringify(this.taskAccess));
    }
  }

  public closeAndClear() {
    this.allAccessRight = false;
    this.$modal.hide("taskAccess");
    this.defaultButtonName = null;
  }

  public getAllAccess(type) {
    if (type == "allAccess") {
      this.userAccess.edit = true;
      this.userAccess.create = true;
      this.userAccess.view = true;
    } else if (type == "create") {
      if (!this.userAccess.create) {
        this.userAccess.edit = false;
        this.userAccess.allAccess = false;
        this.userAccess.view = false;
      } else {
        this.userAccess.create = true;
        this.userAccess.edit = true;
        this.userAccess.view = true;
      }
    } else if (type == "edit") {
      if (!this.userAccess.edit) {
        this.userAccess.create = false;
        this.userAccess.allAccess = false;
        this.userAccess.view = false;
      } else {
        this.userAccess.create = true;
        this.userAccess.edit = true;
        this.userAccess.view = true;
      }
    } else if (type == "create" || type == "edit" || type == "view") {
      this.userAccess.allAccess = false;
    }
  }

  public async taskAccessOfStaff(i, type, x) {
    try {
      if (type == "allAccess") {
        this.roles.forEach((e, i) => {
          this.staff.push({
            taskId: e.id,
            name: e.name,
            processorId: this.wemloStaffData.userId,
            checked: x
          });
          e.checked = x;
        });
      } else {
        if (type == "Conditional Approval") {
          this.pushTaskObj(x);
        } else {
          this.staff.push({
            taskId: this.roles[i].id,
            name: this.roles[i].name,
            processorId: this.wemloStaffData.userId,
            checked: this.roles[i].checked
          });
        }
      }
      this.checkTaskAccess();
    } catch (error) {
      console.log(error);
    }
  }

  public checkTaskAccess() {
    let hasAllTaskAcess = this.roles.filter(e => e.checked == true);
    if (hasAllTaskAcess.length == this.roles.length) this.allAccessRight = true;
    else this.allAccessRight = false;
  }

  public async pushTaskObj(checked) {
    this.roles.forEach(role => {
        if (["Suspended", "Denial"].includes(role.name)) {
          role.checked = checked;
        }
      }
    );
    let taskInfo = this.roles.filter(r => ["Suspended", "Denial", "Conditional Approval"].includes(r.name));
    taskInfo.forEach(t => {
      let taskObj = {
        taskId: t.id,
        name: t.name,
        // processors: {
        //   id: this.wemloStaffData.userId,
        //   addedByUserType: this.wemloStaffData.addedByUserType,
        //   addedBy:this.wemloStaffData.addedBy
        //   },
        processorId: this.wemloStaffData.userId,
        checked: t.checked
      };
      this.staff.push(taskObj);
    });
  }

  public async saveTaskAccessRight() {
    try {
      this.taskAccessLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/updateRoleOfProcessor",
        {
          // userId: this.wemloStaffData.userId,
          role: this.staff
        });
      this.taskAccessLoader = false;

      this.$modal.hide("taskAccess");
      this.$snotify.success("Task Assigned");
      this.staff = [];
      // this.taskAccess.allAccess = !this.taskAccess.allAccess;
    } catch (error) {
      console.log(error);
      this.taskAccessLoader = false;
    }
  }

  public async saveAccessRight() {
    try {
      this.accessLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/saveAccessRight/",
        {
          userId: this.wemloStaffData.userId,
          access: this.userAccess,
          reportees: this.wemloInfoReporties
        });
      this.reportees = [];
      this.wemloInfoReporties = [];
      this.userAccess = {
        create: false,
        allAccess: false,
        edit: false
      };
      this.accessLoader = false;
      this.$snotify.success("Save access right");
      this.$modal.hide("accessRight");
      await this.getWemloUserList(true);
    } catch (error) {
      console.log(error);
      this.accessLoader = false;
    }
  }
  public async getTaskAccessRight() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getTaskAccessRight",
        {
          userId: this.wemloStaffData.userId
        });
      // this.roles = response.data.filter(e=>e.name!='Suspended' && e.name!='Denial');
      this.roles = response.data;
      // this.taskRole = response.data;
    } catch (error) {
      console.log(error);
    }
  }
  public checkAccessRight() {
    if (this.userTypes == "Wemlo Processor") {
      let access = this.$store.state.sessionObject.access;
      if (
        access && access[0] &&
        access[0].canAccess &&
        access[0].canAccess == true &&
        access[0].component == "list-processor-users"
      ) {
        access[0].subSections.forEach(ele => {
          if (ele.accessType == "create" && ele.canAccess == true) {
            this.accessCreate = true;
          }
          if (ele.accessType == "edit" && ele.canAccess == true) {
            this.accessEdit = true;
          }
          if (ele.accessType == "view" && ele.canAccess == true) {
            this.accessView = true;
          }
          if (ele.accessType == "allAccess" && ele.canAccess == true) {
            this.accessAllAccess = true;
            this.accessEdit = true;
            this.accessCreate = true;
            this.accessView = true;
          }
        });
      }
    }
  }

  public async getProcessorLoanDetails() {
    try {
      this.$modal.show("viewLoanCount");
      this.countLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/getProcessorLoanDetails",
        {
          staffType: this.searchStaff,
          brokerId: this.brokerName ? this.brokerName.userId : null,
          userType: this.$store.state.sessionObject.type,
          userId: this.$store.state.sessionObject.userId
        });

      this.processorInfo = response.data;
      this.countLoader = false;

    } catch (error) {
      this.countLoader = false;

      console.log(error);
    }
  }

  public async getWemloProcessorManagerList() {
    let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getAllWemloProcessorList",
        {
          params: {
            isManager: true,
          },
        }
      );
      this.processorManagerList = response.data.wemloStaffList;
  }

  public async searchBroker(brokerName) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/searchBroker",
        { brokerName: brokerName });
      this.brokers = response.data.brokers;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * All ratings and reviews of processor
   */
  public async ratingsAndReviews(id) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/toGetAllRatingsANdReviews",
        { id: id });
      this.processorRatingData = response.data;
    } catch (error) {
      console.log(error, "ratingsAndReviews");
    }
  }

  public getFirstLetter(name){
    return name.charAt(0);
  }

  public routeToReport() {
    this.$router.push({
      path: this.$getCurrentUserTypePath("processor-report")
    });
  }

  async mounted() {
    this.checkAccessRight();
    await this.getWemloUserList(true);
    await this.getWemloProcessorManagerList();
  }
}
